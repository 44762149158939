import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useStore } from "../../store";
import { useRef, useState } from "react";
export default function ScreenNav2() {
    const { user, setUser } = useStore();
    const [firstClick, setFirstClick] = useState(false)
    const navigate = useNavigate()
    const [checkedPN, setCheckedPN] = useState(false);
    const select1 = useRef<HTMLSelectElement>(null)
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    function handleSubmit() {
        setFirstClick(true)
            navigate('/')
    }
    
    
    /** 60 -1           Porcentajes de right para digitos vista PC
    55 - 2
    48 - 3
    43 - 4
    **/ 

    return (
        <>
            <img
                className="myVideo"
                style={{
                    minHeight: '100svh',
                    zIndex: '-1',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%'
                }}
                src={`${isMobile ? '../assets/navidad/BackgroundYaFuisteMob.png' : '../assets/navidad/BackgroundYaFuiste.png'}`}
                alt="Background"
            />

            <div className="flex flex-col min-h-screen overflow-hidden relative z-10 animate-fade justify-center items-center bg-center bg-cover">
                <div className="relative ml-[10%] md:ml-[15%]">
                    <motion.img
                        className=" w-11/12 md:w-3/4"
                        src="../assets/navidad/YaFuiste.png"
                        alt="Home"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 1,
                            delay: 1
                        }}
                    />

                    <div className="flex cursor-pointer flex-row justify-center top-[96%] md:top-[20%] left-[35%] md:left-[60%]  items-center gap-14 absolute ">
                        
                        <motion.img
                            className="w-2/4 md:w-3/4 shadow-md rounded-full"
                            src="../assets/navidad/CerrarVerde.png"
                            alt="Home"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            onClick={() => handleSubmit()}
                            transition={{
                                duration: 1,
                                delay: 1
                            }}
                        />
                    </div>
                </div>

            </div>
        </>
    );

}