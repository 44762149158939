import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useAudioStore } from "../../AudioStore";
let soundtrack = new Audio("/assets/biriween/soundtrack.mp3")

export default function HeaderNav(){

    const [found, setFound] = useState(false);
    const [checkedPN, setCheckedPN] = useState(false);
    const [firstClick, setFirstClick] = useState(false)
    const { setAudio1, audio1, setVolume, volume, inside, audio2} = useAudioStore();

    function handleSubmit(){
        setAudio1(soundtrack);
        setFirstClick(true)
        setVolume(0.2)
        if(checkedPN && audio1){
          audio1.currentTime = 0
          audio1.play()
          audio1.addEventListener('ended', function() {
            this.currentTime = 0;
            this.play();
          }, false);
          setFound(true)
        }
      }

    useEffect(()=>{
        if(window.location.pathname === '/biriween/4')
        {
            if(audio1 && inside){
                audio1.pause()
                audio1.currentTime = 0
                setTimeout(() => {
                    inside.play()
                    inside.addEventListener('ended', function() {
                        this.currentTime = 0;
                        this.play();
                      }, false);
                }, 1000);
              }
            
        }
            
    },[window.location.pathname])

    function mutePage() {
        if(volume == 0)
        {
            if(window.location.pathname === '/biriween/4'){
                inside?.play()
                audio2?.play()
            }else{
                audio1?.play()
            }
            setVolume(0.2)
        }
        else{
            audio1?.pause()
            audio2?.pause()
            inside?.pause()
            setVolume(0)
        }
            
    }

    return (
        <>
            <header className="-mb-[3.15rem] md:-mb-[3.6rem] lg:-mb-[4.6rem] xl:-mb-[5.55rem] top-0 left-0 right-0 z-[100] px-2 absolute">
                <div className="mx-auto container pt-1">
                    <div className="flex justify-between items-center mx-3 mt-2">
                        <div>
                            <Link to="/"><img className="w-10 lg:w-14 hover:scale-110 transition-all cursor-pointer" src="/assets/navidad/home.png" alt="logotipo"/></Link>
                        </div>
                        <div>
                            <Link to="/biriween"><img className="w-20 md:w-24 lg:w-32 xl:w-40" src="/assets/marinela-logo.png" alt="logotipo"/></Link>
                        </div>
                        <div>
                            <Link to="/biriween"><img className="w-16 md:w-20 lg:w-24 xl:w-32" src="/assets/pinguinos.png" alt="logotipo"/></Link>
                        </div>
                    </div> 
                </div>
            </header>

            <Outlet />

            <footer className="w-full z-50 px-0 -mt-9 relative">

                 <div className="flex justify-center items-center mx-3 text-center">
                    <div>
                        <p
                            className="text-[#ffffffad] transition-all text-center text-[0.40rem] md:text-xs -mb-1"
                        >® MARCAS REGISTRADAS; D.R. © 2024 UTILIZADOS
                        BAJO LICENCIA DE SU TITULAR GRUPO BIMBO, S.A.B DE
                        C.V</p>
                    </div>
                </div>
                <div className="flex justify-between items-center mx-3 ">
                        <div>
                        <a href="https://www.bimbo.com.mx/es/terminos-y-condiciones" target="_blank" rel="noreferrer"
                            className="text-[#ffffffad] hover:text-[#ffffff] transition-all underline text-[0.55rem] md:text-xs"
                        >Términos y condiciones</a>
                        </div>
                        <div>
                            <p
                                className="text-[#ffffffad] transition-all text-center text-[0.55rem] md:text-xs"
                            >"COME BIEN"</p>
                        </div>
                        <div>
                            <a href="https://privacy.grupobimbo.com/mexico/policy/sitiosweb/index.html" target="_blank" rel="noreferrer"
                                className="text-[#ffffffad] hover:text-[#ffffff] transition-all underline text-[0.55rem] md:text-xs"
                            >Aviso de privacidad</a>
                        </div>
                </div> 
        
            </footer> 
        </>
    )
}